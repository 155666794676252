const TODO_CONFIG_KEY = 'todoConfig';

export const getDefaultTodoConfig = () => ({
  top: { backgroundColor: '#f8d7da', color: '#721c24', fontSize: 16 },
  middle: { backgroundColor: '#d1ecf1', color: '#0c5460', fontSize: 16 },
  bottom: { backgroundColor: '#d4edda', color: '#155724', fontSize: 16 },
  completed: { backgroundColor: '#f1f1f1', color: '#6c757d', fontSize: 16 },
});

export const loadTodoConfig = () => {
  const savedConfig = localStorage.getItem(TODO_CONFIG_KEY);
  return savedConfig ? JSON.parse(savedConfig) : getDefaultTodoConfig();
};

export const saveTodoConfig = (config) => {
  localStorage.setItem(TODO_CONFIG_KEY, JSON.stringify(config));
};
