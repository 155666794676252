import React, { useState } from 'react';
import { makeStyles, Paper, Typography, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@material-ui/core';
import {
  ChevronLeft,
  ArrowLeft,
  ArrowRight,
  ChevronRight,
  Edit,
  Delete
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  statBox: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
  },
  iconButton: {
    padding: 0,
    margin: 0,
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
      color: 'rgba(0, 0, 0, 0.2)',
    },
  },
}));

function Statistics({ totalWork, totalRest, updateTotalWork, updateTotalRest }) {
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingWork, setEditingWork] = useState(true);
  const [editValue, setEditValue] = useState('');

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    } else {
      return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    }
  };

  const parseTime = (timeString) => {
    const parts = timeString.split(':').map(Number);
    if (parts.length === 3) {
      return parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      return parts[0] * 60 + parts[1];
    } else {
      return 0;
    }
  };

  const handleEdit = (isWork) => {
    setEditingWork(isWork);
    setEditValue(formatTime(isWork ? totalWork : totalRest));
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSave = () => {
    const totalSeconds = parseTime(editValue);
    if (editingWork) {
      updateTotalWork(totalSeconds);
    } else {
      updateTotalRest(totalSeconds);
    }
    setEditDialogOpen(false);
  };

  const adjustTime = (isWork, amount) => {
    const currentTotal = isWork ? totalWork : totalRest;
    const newTotal = Math.max(0, currentTotal + amount);
    if (isWork) {
      updateTotalWork(newTotal);
    } else {
      updateTotalRest(newTotal);
    }
  };

  const renderStatBox = (title, total, isWork) => (
    <Grid item xs={6}>
      <Paper className={classes.statBox}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="h4">{formatTime(total)}</Typography>
        <div className={classes.buttonGroup}>
          <IconButton className={classes.iconButton} onClick={() => adjustTime(isWork, -600)}>
            <ChevronLeft />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={() => adjustTime(isWork, -60)}>
            <ArrowLeft />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={() => adjustTime(isWork, 60)}>
            <ArrowRight />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={() => adjustTime(isWork, 600)}>
            <ChevronRight />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={() => handleEdit(isWork)}>
            <Edit />
          </IconButton>
          <IconButton className={classes.iconButton} onClick={() => adjustTime(isWork, -total)}>
            <Delete />
          </IconButton>
        </div>
      </Paper>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={2} className={classes.root}>
        {renderStatBox("Total Work", totalWork, true)}
        {renderStatBox("Total Rest", totalRest, false)}
      </Grid>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>{editingWork ? "Edit Total Work" : "Edit Total Rest"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Time (HH:MM:SS or MM:SS)"
            type="text"
            fullWidth
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Statistics;
