import React from 'react';

function FlashScreen({ isFlashing, mode }) {
  const getBackgroundColor = () => {
    const opacity = isFlashing ? 0.5 : 0;
    if (mode === 'work') {
      return `rgba(0, 255, 0, ${opacity})`; // Green for work
    } else if (mode === 'rest') {
      return `rgba(255, 0, 0, ${opacity})`; // Red for rest
    } else {
      return 'transparent'; // No color for stopped mode
    }
  };

  const flashStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: getBackgroundColor(),
    zIndex: -1, // Place it behind other components
    pointerEvents: 'none',
    transition: 'background-color 0.5s ease-in-out',
    mixBlendMode: 'multiply', // This will blend the flash color with the background
  };

  return <div style={flashStyle} />;
}

export default FlashScreen;
