import React from 'react';
import { IconButton } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    fontSize: '1.5rem',
  },
}));

function ConfigButtons({ onConfigOpen }) {
  const classes = useStyles();
  return (
    <IconButton color="secondary" onClick={onConfigOpen} className={classes.iconButton}>
      <Settings />
    </IconButton>
  );
}

export default ConfigButtons;
