import React from 'react';
import { Typography } from '@material-ui/core';

function Title() {
  return (
    <Typography variant="h3" component="h1" gutterBottom>
      FMPomo Plus
    </Typography>
  );
}

export default Title;