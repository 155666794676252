class AudioManager {
  constructor() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.sounds = {};
    this.currentSource = null;
  }

  async loadSound(name, url) {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
      this.sounds[name] = audioBuffer;
    } catch (error) {
      // Error loading sound
    }
  }

  stopCurrentSound() {
    if (this.currentSource) {
      this.currentSource.stop();
      this.currentSource = null;
    }
  }

  playSound(name, volume = 1) {
    if (this.sounds[name]) {
      this.stopCurrentSound();
      
      const source = this.audioContext.createBufferSource();
      const gainNode = this.audioContext.createGain();
      
      source.buffer = this.sounds[name];
      source.connect(gainNode);
      gainNode.connect(this.audioContext.destination);
      
      // Set the volume (0 to 1)
      gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
      
      source.start();
      this.currentSource = source;
    } else {
      // Sound not found
    }
  }
}

const audioManager = new AudioManager();

export const loadSounds = async () => {
  await audioManager.loadSound('work', '/work.mp3');
  await audioManager.loadSound('rest', '/rest.mp3');
  await audioManager.loadSound('tick', '/tick.mp3');
  await audioManager.loadSound('extwork', '/extwork.mp3');
  await audioManager.loadSound('extrest', '/extrest.mp3');
};

export const playSound = (name, volume = 1) => {
  audioManager.playSound(name, volume);
};

export const playWorkSound = () => {
  audioManager.playSound('work');
};

export const playRestSound = () => {
  audioManager.playSound('rest');
};

export const playExtWorkSound = () => {
  audioManager.playSound('extwork');
};

export const playExtRestSound = () => {
  audioManager.playSound('extrest');
};

export default audioManager;
