import { createTheme } from '@material-ui/core/styles';

const themes = {
  default: createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#2196f3',
      },
      secondary: {
        main: '#f50057',
      },
    },
  }),
  dark: createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
    },
  }),
  custom1: createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#4caf50',
      },
      secondary: {
        main: '#ff9800',
      },
    },
  }),
};

export const getTheme = (themeName) => {
  return themes[themeName] || themes.default;
};

export const themeNames = Object.keys(themes);