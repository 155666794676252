import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    margin: theme.spacing(2, 0),
  },
  largeButton: {
    fontSize: '2rem', // tamaño de la fuente
    padding: theme.spacing(1, 3), // controla el espacio interno del botón
    minWidth: '80px', // ancho mínimo del botón
    minHeight: '30px', // altura mínima del botón
  },
}));

function ActionButtons({ onWork, onRest, onStop, mode }) {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button variant="contained" color="primary" className={classes.largeButton} onClick={onWork}>
        Work
      </Button>
      <Button variant="contained" color="secondary" className={classes.largeButton} onClick={onRest}>
        Rest
      </Button>
      {mode !== 'stopped' && (
        <Button variant="contained" className={classes.largeButton} onClick={onStop}>
          Stop
        </Button>
      )}
    </div>
  );
}

export default ActionButtons;