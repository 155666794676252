import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  timerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  timer: {
    fontSize: '8rem',
    fontWeight: 'bold',
  },
  workTimer: {
    color: theme.palette.success.main,
  },
  restTimer: {
    color: theme.palette.error.main,
  },
  stoppedTimer: {
    color: theme.palette.text.primary,
  },
  flashingWork: {
    animation: '$flashTextWork 1s infinite',
  },
  flashingRest: {
    animation: '$flashTextRest 1s infinite',
  },
  '@keyframes flashTextWork': {
    '0%': { color: theme.palette.success.main },
    '50%': { color: theme.palette.success.light },
    '100%': { color: theme.palette.success.main },
  },
  '@keyframes flashTextRest': {
    '0%': { color: theme.palette.error.main },
    '50%': { color: theme.palette.error.light },
    '100%': { color: theme.palette.error.main },
  },
}));

function Timer({ value, mode, isCountingUp, isFlashing }) {
  const classes = useStyles();

  const timerClassName = `${classes.timer} ${
    mode === 'work'
      ? classes.workTimer
      : mode === 'rest'
      ? classes.restTimer
      : classes.stoppedTimer
  } ${
    isFlashing
      ? mode === 'work'
        ? classes.flashingWork
        : classes.flashingRest
      : ''
  }`;

  return (
    <div className={classes.timerContainer}>
      <Typography className={timerClassName} variant="h1">
        {value}
      </Typography>
    </div>
  );
}

export default Timer;