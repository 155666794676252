import React from 'react';
import { Typography, TextField } from '@material-ui/core';

function TodoListConfig({ todoConfig, onTodoConfigChange }) {
  const handleStyleChange = (zone, styleKey, value) => {
    onTodoConfigChange({
      ...todoConfig,
      [zone]: {
        ...todoConfig[zone],
        [styleKey]: value,
      },
    });
  };

  const zones = [
    { key: 'top', label: 'Alta Prioridad' },
    { key: 'middle', label: 'Prioridad Media' },
    { key: 'bottom', label: 'Baja Prioridad' },
    { key: 'completed', label: 'Completadas' },
  ];

  return (
    <div>
      {zones.map(({ key, label }) => (
        <div key={key} style={{ marginBottom: 16 }}>
          <Typography variant="h6">{`Estilos de la zona ${label}`}</Typography>
          <TextField
            label="Color de fondo"
            type="text"
            value={todoConfig[key]?.backgroundColor || ''}
            onChange={(e) => handleStyleChange(key, 'backgroundColor', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Color del texto"
            type="text"
            value={todoConfig[key]?.color || ''}
            onChange={(e) => handleStyleChange(key, 'color', e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Tamaño de fuente (px)"
            type="number"
            value={todoConfig[key]?.fontSize || ''}
            onChange={(e) => handleStyleChange(key, 'fontSize', Number(e.target.value))}
            fullWidth
            margin="normal"
          />
        </div>
      ))}
    </div>
  );
}

export default TodoListConfig;
