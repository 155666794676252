import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Checkbox, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Divider } from '@material-ui/core';
import { Add, Delete, ArrowUpward, ArrowDownward, Settings } from '@material-ui/icons';
import { saveTodoTasksToCookie, loadTodoTasksFromCookie, saveTodoStylesToCookie, loadTodoStylesFromCookie, getDefaultTodoStyles } from '../cookieManager';

const useStyles = makeStyles((theme) => ({
  todoList: {
    width: '100%',
  },
  zone: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
  },
  zoneHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  task: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    cursor: 'move',
  },
  taskText: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  completedTask: {
    '& .MuiInputBase-input': {
      textDecoration: 'line-through !important',
      color: `${theme.palette.text.disabled} !important`,
    },
  },
  highlightedTask: {
    backgroundColor: theme.palette.action.selected,
    transition: 'background-color 0.3s ease',
  },
  configDialog: {
    '& .MuiDialog-paper': {
      width: '80%',
      maxWidth: 500,
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
}));

const TodoList = ({ theme, todoConfig }) => {
  const classes = useStyles(theme);
  const [tasks, setTasks] = useState({
    top: [],
    middle: [],
    bottom: [],
    completed: []
  });
  const [configOpen, setConfigOpen] = useState(false);
  const [zoneStyles, setZoneStyles] = useState(todoConfig);
  const [draggedTask, setDraggedTask] = useState(null);
  const [restoredTask, setRestoredTask] = useState(null);
  const taskRefs = useRef({});

  useEffect(() => {
    const savedTasks = loadTodoTasksFromCookie();
    if (savedTasks) setTasks(savedTasks);
    setZoneStyles(todoConfig);
  }, [todoConfig]);

  useEffect(() => {
    saveTodoTasksToCookie(tasks);
  }, [tasks]);


  useEffect(() => {
    if (restoredTask) {
      const timer = setTimeout(() => {
        setRestoredTask(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [restoredTask]);

  const addTask = (zone, index = -1) => {
    const newTaskId = Date.now();
    setTasks(prev => {
      const newTasks = { ...prev };
      const newTask = { id: newTaskId, text: '', completed: false, originalZone: zone };
      if (index === -1) {
        newTasks[zone] = [...newTasks[zone], newTask];
      } else {
        newTasks[zone] = [
          ...newTasks[zone].slice(0, index + 1),
          newTask,
          ...newTasks[zone].slice(index + 1)
        ];
      }
      return newTasks;
    });
    setTimeout(() => {
      if (taskRefs.current[`${zone}-${newTaskId}`]) {
        taskRefs.current[`${zone}-${newTaskId}`].focus();
      }
    }, 0);
  };

  const deleteTask = (zone, taskId) => {
    setTasks(prev => ({
      ...prev,
      [zone]: prev[zone].filter(task => task.id !== taskId)
    }));
  };

  const toggleComplete = (zone, taskId) => {
    setTasks(prev => {
      const updatedTasks = { ...prev };
      const taskIndex = updatedTasks[zone].findIndex(task => task.id === taskId);
      if (taskIndex !== -1) {
        const task = updatedTasks[zone][taskIndex];
        task.completed = !task.completed;
        if (task.completed) {
          updatedTasks[zone].splice(taskIndex, 1);
          updatedTasks.completed.push(task);
        } else {
          updatedTasks.completed.splice(taskIndex, 1);
          const targetZone = task.originalZone || zone;
          updatedTasks[targetZone].push(task);
          setRestoredTask({ id: task.id, zone: targetZone });
        }
      }
      return updatedTasks;
    });
  };

  const editTask = (zone, taskId, newText) => {
    setTasks(prev => ({
      ...prev,
      [zone]: prev[zone].map(task => 
        task.id === taskId ? { ...task, text: newText } : task
      )
    }));
  };

  const handleKeyPress = (e, zone, taskId, index) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      addTask(zone, index);
    }
  };

  const handleBlur = (zone, taskId) => {
    setTasks(prev => {
      const updatedTasks = { ...prev };
      const taskIndex = updatedTasks[zone].findIndex(task => task.id === taskId);
      if (taskIndex !== -1 && updatedTasks[zone][taskIndex].text.trim() === '') {
        updatedTasks[zone].splice(taskIndex, 1);
      }
      return updatedTasks;
    });
  };

  const onDragStart = (e, task, zone) => {
    setDraggedTask({ task, zone });
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/plain', task.id);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (e, targetZone) => {
    e.preventDefault();
    if (!draggedTask) return;

    const { task, zone: sourceZone } = draggedTask;
    if (sourceZone !== targetZone) {
      setTasks(prev => ({
        ...prev,
        [sourceZone]: prev[sourceZone].filter(t => t.id !== task.id),
        [targetZone]: [...prev[targetZone], { ...task, completed: targetZone === 'completed', originalZone: task.originalZone || sourceZone }]
      }));
    }
    setDraggedTask(null);
  };

  const deleteCompletedTasks = () => {
    setTasks(prev => ({
      ...prev,
      completed: []
    }));
  };

  const deleteAllTasks = () => {
    setTasks({ top: [], middle: [], bottom: [], completed: [] });
  };

  const moveTask = (zone, index, direction) => {
    const newTasks = { ...tasks };
    const [movedTask] = newTasks[zone].splice(index, 1);
    
    if (direction === 'up' && zone !== 'top') {
      const newZone = zone === 'middle' ? 'top' : 'middle';
      newTasks[newZone].push(movedTask);
    } else if (direction === 'down' && zone !== 'bottom') {
      const newZone = zone === 'middle' ? 'bottom' : 'middle';
      newTasks[newZone].unshift(movedTask);
    } else {
      const newIndex = direction === 'up' ? Math.max(0, index - 1) : Math.min(newTasks[zone].length, index + 1);
      newTasks[zone].splice(newIndex, 0, movedTask);
    }

    setTasks(newTasks);
  };

  const renderTask = (task, zone, index) => (
    <div
      key={task.id}
      className={`${classes.task} ${task.completed ? classes.completedTask : ''} ${restoredTask && restoredTask.id === task.id && restoredTask.zone === zone ? classes.highlightedTask : ''}`}
      draggable
      onDragStart={(e) => onDragStart(e, task, zone)}
      style={{
        backgroundColor: todoConfig[zone]?.backgroundColor || '#ffffff',
        color: todoConfig[zone]?.color || '#000000',
        fontSize: todoConfig[zone]?.fontSize ? `${todoConfig[zone].fontSize}px` : '16px',
      }}
    >
      <Checkbox
        checked={task.completed}
        onChange={() => toggleComplete(zone, task.id)}
      />
      <TextField
        inputRef={el => taskRefs.current[`${zone}-${task.id}`] = el}
        className={classes.taskText}
        value={task.text}
        onChange={(e) => editTask(zone, task.id, e.target.value)}
        onKeyPress={(e) => handleKeyPress(e, zone, task.id, index)}
        onBlur={() => handleBlur(zone, task.id)}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: zoneStyles[zone].fontSize,
          },
        }}
      />
      <IconButton onClick={() => deleteTask(zone, task.id)} size="small">
        <Delete />
      </IconButton>
      {zone !== 'completed' && (
        <>
          <IconButton onClick={() => moveTask(zone, index, 'up')} size="small">
            <ArrowUpward />
          </IconButton>
          <IconButton onClick={() => moveTask(zone, index, 'down')} size="small">
            <ArrowDownward />
          </IconButton>
        </>
      )}
    </div>
  );

  const renderZone = (zone) => (
    <div 
      key={zone}
      className={classes.zone}
      style={{
        backgroundColor: todoConfig[zone]?.backgroundColor || '#ffffff',
        color: todoConfig[zone]?.color || '#000000',
        fontSize: todoConfig[zone]?.fontSize ? `${todoConfig[zone].fontSize}px` : '16px',
      }}
      onDragOver={onDragOver}
      onDrop={(e) => onDrop(e, zone)}
    >
      <div className={classes.zoneHeader}>
        {zone !== 'completed' && (
          <IconButton className={classes.addButton} onClick={() => addTask(zone)} size="small">
            <Add />
          </IconButton>
        )}
        <Typography variant="h6" style={{ fontSize: zoneStyles[zone].fontSize }}>
          {zone.charAt(0).toUpperCase() + zone.slice(1)}
        </Typography>
      </div>
      {tasks[zone].map((task, index) => renderTask(task, zone, index))}
    </div>
  );

  return (
    <div className={classes.todoList}>
      <Typography variant="h5" gutterBottom>Todo List</Typography>
      {renderZone('top')}
      {renderZone('middle')}
      {renderZone('bottom')}
      {renderZone('completed')}
      <Divider />
      <div className={classes.buttonGroup}>
        <Button onClick={deleteCompletedTasks} variant="outlined" color="secondary">
          Delete Completed
        </Button>
        <Button onClick={deleteAllTasks} variant="outlined" color="secondary">
          Delete All
        </Button>
      </div>
    </div>
  );
};

export default TodoList;
